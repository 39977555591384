import throttle from 'lodash.throttle';
import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import logger from 'redux-logger';
import thunk from "redux-thunk";
import mainReducer from './mainReducer';
// //The saveState function is called inside the store.subscribe listener so it is called every time the storage state changes. However, it should not be called too often because it uses the expensive JSON.stringify operation.
// To solve this, we will use a library called lodash which includes a handy utility called throttle.Wrapping the callback in a throttle ensures that the inner function that is passed in is not going to be called more often than the number of milliseconds that is specified.Now, even if this store gets called really fast, we have a guarantee that we will only write to the localStorage at most once a second.
import { loadState, saveState } from '../utils/utils';

const persistedState = loadState(); // looks in local storage and pass it as argument to createStore

const rootReducer = combineReducers({
    mainReducer: mainReducer
})

const middleware = [thunk, logger] // add middleware here to keep compose function clean
//to prevent webpack from throwing error during gatsby develop,
const windowGlobal = typeof window !== "undefined" && window
const devtools =
    process.env.NODE_ENV === "development" && windowGlobal.devToolsExtension
        ? window.__REDUX_DEVTOOLS_EXTENSION__ &&
        window.__REDUX_DEVTOOLS_EXTENSION__()
        : f => f
//webpack throws error during gatsby develop,

const store = createStore(
    rootReducer,
    persistedState,
    compose(
        applyMiddleware(...middleware), //spread operation with middleware array
        devtools,

    )
)

store.subscribe(throttle(() => {
    saveState(store.getState());
}, 1000));
//Throttle: invokes a function at most once per every X milliseconds.

export default store
