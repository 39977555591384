const _ = require("lodash")
// formats date from "2019-09-23T14:39:55"  to Monday, 23 September 2019
export const formatDate = date => {
  let formattedDate
  formattedDate = new Date(date).toLocaleDateString(undefined, {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  })
  return formattedDate
}
//{title: "A celebration of @ italian_wines", url: "https://miaggrego.vinitalytour.com/a-celebration-o…alian_wines-the-platform-to-share-and-learn-from/"}
// grab "a-celebration-of-italian_wines-the-platform-to-share-and-learn-from/" from object
// if last item of array is empty, returns second last
export const grabSlugFromArray = object => {
  let urlArray = object.url.split("/")
  let link =
    object.url.split("/")[urlArray.length - 1].length < 1
      ? object.url.split("/")[urlArray.length - 2]
      : object.url.split("/")[urlArray.length - 1]
  return link
}

export const grabSlugFromString = string => {
  let string_splits = string.split("/")
  let slug =
    string.split("/")[string_splits.length - 1].length < 1
      ? string.split("/")[string_splits.length - 2]
      : string.split("/")[string_splits.length - 1]
  return slug
}

export const getSingleTagsFromArray = tags => {
  // convert tags into single unique words
  const __tags = tags.map(i => i.fieldValue.split(","))
  return _.uniq(_.flattenDeep(__tags))
}

export const loadState = () => {
  try {
    const serializedState = localStorage.getItem("state")
    if (serializedState === null) {
      return undefined
    }
    return JSON.parse(serializedState)
  } catch (err) {
    return undefined
  }
}

export const saveState = state => {
  try {
    const serializedState = JSON.stringify(state)
    localStorage.setItem("state", serializedState)
  } catch {
    // ignore write errors
  }
}
// console.log('%c%s',
//   'color: green; background: yellow; font-size: 24px;','Success!')