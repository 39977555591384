// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-blog-md-template-js": () => import("./../src/templates/blog-md-template.js" /* webpackChunkName: "component---src-templates-blog-md-template-js" */),
  "component---src-templates-recipe-md-template-js": () => import("./../src/templates/recipe-md-template.js" /* webpackChunkName: "component---src-templates-recipe-md-template-js" */),
  "component---src-templates-wine-md-template-js": () => import("./../src/templates/wine-md-template.js" /* webpackChunkName: "component---src-templates-wine-md-template-js" */),
  "component---src-templates-tag-template-js": () => import("./../src/templates/tag-template.js" /* webpackChunkName: "component---src-templates-tag-template-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-dashboard-js": () => import("./../src/pages/dashboard.js" /* webpackChunkName: "component---src-pages-dashboard-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-logged-in-js": () => import("./../src/pages/loggedIn.js" /* webpackChunkName: "component---src-pages-logged-in-js" */),
  "component---src-pages-login-js": () => import("./../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-search-js": () => import("./../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-sections-blogs-js": () => import("./../src/pages/sections/Blogs.js" /* webpackChunkName: "component---src-pages-sections-blogs-js" */),
  "component---src-pages-sections-book-reviews-js": () => import("./../src/pages/sections/BookReviews.js" /* webpackChunkName: "component---src-pages-sections-book-reviews-js" */),
  "component---src-pages-sections-food-wine-pairing-js": () => import("./../src/pages/sections/FoodWinePairing.js" /* webpackChunkName: "component---src-pages-sections-food-wine-pairing-js" */),
  "component---src-pages-sections-recipes-js": () => import("./../src/pages/sections/recipes.js" /* webpackChunkName: "component---src-pages-sections-recipes-js" */),
  "component---src-pages-sections-top-10-lists-js": () => import("./../src/pages/sections/Top10Lists.js" /* webpackChunkName: "component---src-pages-sections-top-10-lists-js" */),
  "component---src-pages-sections-trending-topics-js": () => import("./../src/pages/sections/TrendingTopics.js" /* webpackChunkName: "component---src-pages-sections-trending-topics-js" */),
  "component---src-pages-sections-wine-art-js": () => import("./../src/pages/sections/WineArt.js" /* webpackChunkName: "component---src-pages-sections-wine-art-js" */),
  "component---src-pages-sections-wine-culture-js": () => import("./../src/pages/sections/WineCulture.js" /* webpackChunkName: "component---src-pages-sections-wine-culture-js" */),
  "component---src-pages-sections-wines-js": () => import("./../src/pages/sections/wines.js" /* webpackChunkName: "component---src-pages-sections-wines-js" */),
  "component---src-pages-sections-wine-social-js": () => import("./../src/pages/sections/WineSocial.js" /* webpackChunkName: "component---src-pages-sections-wine-social-js" */),
  "component---src-pages-tags-js": () => import("./../src/pages/tags.js" /* webpackChunkName: "component---src-pages-tags-js" */)
}

