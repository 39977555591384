const initialState = { user: '', isLoggedin: false }

const mainReducer = (state = initialState, action) => {

    switch (action.type) {
        case "GET_DATA":
            return {
                ...state,
                SavedArticles: action.payload,
            }
        case "SET_DATA":
            return {
                ...state,
                SavedArticles: action.payload,
            }
        case "SAVE_DATA":
            return {
                ...state,
                data: action.payload,
            }
        case "LOGIN":
            return {
                ...state,
                user: action.payload,
                isLoggedin: true,
                Error: ''
            }

        case "SIGN_UP":
            return {
                ...state,
                user: action.payload,
                isLoggedin: true,
                Error: action.payload.error,

            }
        case "SET_ONLINE_STATUS":
            return {
                ...state,
                user: action.payload,
                isLoggedin: true,
            }
        case "LOGOUT":
            return {
                ...state,
                user: '',
                isLoggedin: action.payload,
                SavedArticles: '',
                Error: '',
            }
        case "ERROR":
            return {
                ...state,
                user: '',
                Error: action.payload,

            }

        default:
            return state

    }
}

export default mainReducer
